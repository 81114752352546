import React from "react";
import classNames from "classnames";
import RibbonsTwo from "@svg/RibbonsTwo";
import { Container, Text, Image, Button, ButtonAlt } from "@atoms";

const Hero = ({
  button,
  className: _className,
  copy,
  cover,
  hideRibbons,
  image,
  lighten,
  link,
}) => {
  return (
    <section className={classNames("relative z-30 min-h-[50vh]", _className)}>
      <div
        className={classNames("relative min-h-[50vh] pt-32 md:pb-32 md:pt-48", {
          "pb-32": !lighten,
        })}
      >
        <Container className="relative z-20">
          <div className="flex flex-wrap gap-12 md:flex-nowrap">
            <div className="flex w-full flex-col md:w-3/5 lg:w-1/2">
              <Text
                richText
                className={classNames(
                  "mt-4 prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark prose-headings:!text-white": !lighten,
                  }
                )}
              >
                {copy}
              </Text>
              {(button?.url || link?.url) && (
                <div className="mt-8 flex items-center space-x-8">
                  {button?.url && (
                    <Button
                      size="md"
                      color={lighten ? "midnight" : "carnation"}
                      to={button.url}
                    >
                      {button.text}
                    </Button>
                  )}
                  {link?.url && (
                    <ButtonAlt to={link.url} icon="arrow">
                      {link.text}
                    </ButtonAlt>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>
        {image && (
          <div
            className={classNames("inset-0", {
              "relative -mt-16 aspect-[4/3] sm:aspect-video md:absolute md:mt-auto md:aspect-auto":
                lighten,
              absolute: !lighten,
              "md:left-[33.33%]": !cover && lighten,
            })}
          >
            <div
              className={classNames("absolute inset-0 z-10 bg-gradient-to-b", {
                "from-white md:bg-gradient-to-r md:via-transparent": lighten,
                "from-midnight-dark bg-blend-multiply md:bg-gradient-to-br md:via-midnight-dark md:opacity-70":
                  !lighten,
              })}
            />
            <Image image={image} eager fill />
          </div>
        )}
        {!hideRibbons && (
          <RibbonsTwo
            color="white"
            className="absolute inset-x-0 -bottom-4 z-10 md:-bottom-[5%]"
          />
        )}
      </div>
    </section>
  );
};

Hero.defaultProps = {
  lighten: false,
  hideRibbons: false,
  cover: false,
};

export default Hero;
